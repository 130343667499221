import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import partner1 from "../../src/assets/img/w8.webp";
import partner2 from "../../src/assets/img/ww-8.webp";
import partner3 from "../../src/assets/img/ww-9.webp";
import partner4 from "../../src/assets/img/ww-10.webp";
import partner5 from "../../src/assets/img/ww-11.webp";
import partner6 from "../../src/assets/img/ww-12.webp";
import { imgBaseURL } from "../utils/api";
const Partners = ({ data }) => {
  const partnerlogo = {
    loop: true,
    margin: 30,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 3000,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 2, // Show 1 item for screens 0px and up
      },
      600: {
        items: 5, // Show 2 items for screens 600px and up
      },
      1000: {
        items: 6, // Show 4 items for screens 1000px and up
      },
    },
  };

  return (
    <section className="partners-logo">
      <div className="container">
        {
          data &&
        <OwlCarousel className="owl-theme" {...partnerlogo}>
          {
            data?.map((item, i) => (
              <div className="item">
                <div className="pxl-item--logo">
                  <img src={imgBaseURL + item?.image} alt="" />
                </div>
              </div>
            ))
          }
        </OwlCarousel>
        }
      </div>
    </section>
  );
};

export default Partners;
