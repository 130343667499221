import React, { useEffect, useState } from "react";

import OwlCarousel from "react-owl-carousel";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HTMLContent from "../Component/HTMLContent";
import { APICALL, imgBaseURL } from "../utils/api";
import Form from "../Component/Form";

const ProductDetails = () => {
  const [show, setShow] = useState(false);
  const {id} = useParams()

  const [productDetails, setProductDetails] = useState(null)
  const [productImg, setProductImg] = useState([])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate()

  const Prodcutslider = {
    items: 1,
    loop: false,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 5000,
    smartSpeed: 700,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };

  const relatedpro = {
    loop: true,
    margin: 30,
    nav: false,
    autoplay: true,
    autoplayTimeout: 3000,
    smartSpeed: 450,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };
  useEffect(() => {
    if (id) {
      ralatedProductFun()
    }
  }, [id])
  const [relatedProduct, setRelatedProduct] = useState([])
  const ralatedProductFun = async () => {
    try {
      const res = await APICALL(`/get-data/product-details`, 'post', {id: id})
      if (res?.status) {
        setProductDetails(res?.details)
        setProductImg(JSON.parse(res?.details?.image))
        setRelatedProduct(res?.related_product)
      }
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <>
      <div className="margindiv-header">
        <section className="product-detailsinner">
          <div className="container">
            <div className="row">
              <div className="col-md-5 mb-md-0 mb-4">
                <div className="slider-box-product">
                  {
                    productDetails &&
                    <OwlCarousel className="owl-theme" {...Prodcutslider}>
                      {
                        productImg?.map((item, i) => (
                          <div className="item">
                            <img src={imgBaseURL + item} alt="Product 1" />
                          </div>
                        ))
                      }
                    </OwlCarousel>
                  }
                </div>
              </div>
              <div className="col-md-7 mb-md-0 mb-4 ps-md-4">
                <div className="product-details-content">
                  <h2>{productDetails?.title}</h2>
                  <div className="description">Description</div>
                  <HTMLContent data={productDetails?.description} />
                  <button
                    type="button"
                    onClick={handleShow}
                    className="btn mt-4"
                  >
                    Product inquiry <i className="fa fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {
          relatedProduct?.length > 0 &&
          <section className="related-products">
            <div className="container">
              <div className="section-title mb-md-5 mb-4">
                <span>Find More Essentials That Match</span>
                <h2>Related Products</h2>
              </div>
              <OwlCarousel className="row" {...relatedpro}>
                {relatedProduct?.map((product) => (
                  <div className="item" key={product.id}>
                    <div className="product-box">
                      <a href={`/product-details/${product?.id}`}>
                        <img src={imgBaseURL + JSON.parse(product.image)[0]} alt={product.title} />
                        <div className="title-product">
                          <h2>{product.title}</h2>
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </section>
        }
      </div>
      {show && (
        <div className="modal show" style={{ display: "block" }} tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content conatctuseform p-4 pt-3">
              <div className="modal-header p-0 border-0 mb-3">
                <h5 className="modal-title">Product Inquiry</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body px-0 pb-0">
                <Form productDetails={productDetails}/>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" onClick={handleClose}></div>
        </div>
      )}
    </>
  );
};

export default ProductDetails;
