import { useEffect } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import './index.css';
import Home from "./page/Home";
import "./Responsive.css";
import About from "./page/About";
import Contact from "./page/Contact";
import Product from "./page/Product";
import ProductDetails from "./page/ProductDetails";
import Main from "./layout/Main";
import ScrollToTop from "./Component/ScrollToTop";
import Faq from './page/Faq';
import Sitemap from "./page/Sitemap";
import PrivacyPolicy from "./page/PrivacyPolicy";
import TermsofUse from "./page/TermsofUse";


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Main cmp={Home} />} />
        <Route path='/about' element={<Main cmp={About} />} />
        <Route path='/contact' element={<Main cmp={Contact} />} />
        <Route path='/product' element={<Main cmp={Product} />} />
        <Route path='/faq' element={<Main cmp={Faq} />} />
        <Route path='/product-details/:id' element={<Main cmp={ProductDetails} />} />
        <Route path='/privacy-policy' element={<Main cmp={PrivacyPolicy} />} />
        <Route path='/sitemap' element={<Main cmp={Sitemap} />} />
        <Route path='/terms-of-use' element={<Main cmp={TermsofUse} />} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
