import React, { useEffect, useState } from "react";
import bradcrumbbg1 from "../../src/assets/img/breadcrumbbg.png";
import Partners from "../Component/Partner";
import { Link } from "react-router-dom";
import { APICALL, imgBaseURL } from "../utils/api";

const Faq = ({mainContent}) => {

  const [activeIndex, setActiveIndex] = useState(0);
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const [faqItems, setFaqItems] = useState([])

  useEffect(() =>{
    ralatedProductFun()
  },[])
  const ralatedProductFun = async () => {
    try {
      const res = await APICALL(`/get-data/faq`)
      if (res?.status) {
        setFaqItems(res?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="margindiv-header">
        <section className="breadrum-tittle" style={{backgroundImage: `url(${imgBaseURL + mainContent?.faq?.banner})`,}}>
          <div className="container">
            <h1>Faq</h1>
            <ul>
              <li> <Link to="/">Home</Link></li>
              <li><i className="fa fa-location-arrow"></i></li>
              <li><span>Faq</span></li>
            </ul>
          </div>
        </section>

        <section className="faq-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="accordion" id="accordionExample">
                  {faqItems.map((item, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className={`accordion-button ${
                            activeIndex !== index ? "collapsed" : ""
                          }`}
                          type="button"
                          onClick={() => toggleAccordion(index)}
                          aria-expanded={activeIndex === index}
                          aria-controls={`collapse${index}`}
                        >
                          {item.question}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          activeIndex === index ? "show" : ""
                        }`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body"><p>{item.answer}</p></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Partners data={mainContent?.logos} />

      </div>
    </>
  );
};

export default Faq;
