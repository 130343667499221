import React from "react";
import bradcrumbbg1 from "../../src/assets/img/breadcrumbbg.png";
import Productlist from "../Component/Productlist";
import { Link } from "react-router-dom";
const Product = ({ mainContent }) => {
  const bradcrumbbg = {
    backgroundImage: `url(${bradcrumbbg1})`,
  };
  return (
    <>
      <div className="margindiv-header">
        <section className="breadrum-tittle" style={bradcrumbbg}>
          <div className="container">
            <h1>Que Solution Products</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="fa fa-location-arrow"></i>
              </li>
              <li>
                <span>Que Solution Products</span>
              </li>
            </ul>
          </div>
        </section>
        {
          mainContent &&
          <section className="product-list">
            <div className="container">
              <Productlist products={mainContent} />
            </div>
          </section>
        }
      </div>
    </>
  );
};

export default Product;
