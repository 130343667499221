import React from 'react'
import { imgBaseURL } from '../utils/api'
import HTMLContent from './HTMLContent'

const WhatWeOffer = ({ data }) => {
    return (
        <>

            <section className="whatweoffer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="section-title mb-md-5 mb-4" data-aos="fade-right">
                                <span className='main_header_tittle'>What We offer</span>
                                <h2>
                                    Bespoke safety solutions, with a focus on end user
                                    experience
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        {
                            data?.map((item, i) => (
                                <div className="col-md-3 col-sm-6 mb-md-0 mb-4 mb-sm-6">
                                    <div className="whtwe-box" data-aos="fade-up">
                                        <img src={imgBaseURL + item?.image} alt="" />
                                        <h3>{item?.title}</h3>
                                        <HTMLContent  data={item?.text}/>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </section>
        </>
    )
}

export default WhatWeOffer