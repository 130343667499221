import React, { useEffect, useState } from "react";
import Partners from "../Component/Partner";
import { APICALL, imgBaseURL } from "../utils/api";
import HTMLContent from '../Component/HTMLContent'

const TermsofUse = ({mainContent}) => {

  const [data, setData] = useState()
  useEffect(() =>{
    getDataFun()
  },[])
  const getDataFun = async () => {
    try {
      const res = await APICALL(`/get-data/terms-of-use`)
      if (res?.status) {
        setData(res?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
    
      <div className="margindiv-header">
        <section className="breadrum-tittle" style={{backgroundImage: `url(${imgBaseURL + data?.banner})`,}}>
          <div className="container">
            <h1>{data?.title}</h1>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <i className="fa fa-location-arrow"></i>
              </li>
              <li>
                <span>{data?.title}</span>
              </li>
            </ul>
          </div>
        </section>
        <section className="staticpage">
          <div className="container">
          <HTMLContent data={data?.json_data?.data}/>
          </div>
        </section>
        <Partners data={mainContent?.logos} />
      </div>
    </>
  );
};

export default TermsofUse;
