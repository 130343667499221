import React, { useEffect, useState } from 'react'
import Header from './Header';
import Footer from './Footer';
import { APICALL } from '../utils/api';
import { useLocation } from 'react-router';

const Main = ({ cmp }) => {
    const { pathname } = useLocation();
    const Component = cmp;
    const [footerContent, setFooterContent] = useState(null)
    const [mainContent, setMainContent] = useState(null)

    useEffect(() => {
        getFooterContent()
        getMainContent()
    }, [])

    useEffect(() =>{
        getMetaTags()
    },[pathname])

    const getFooterContent = async () => {
        try {
            const res = await APICALL(`/get-data/footer`)
            if (res?.status) {
                setFooterContent(res?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getMainContent = async () => {
        try {
            const res = await APICALL(`/get-data/home`)
            if (res?.status) {
                setMainContent(res?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    // const [metaTags, setMetaTags] = useState([])
    const getMetaTags = async () => {
        let mypath = ""
        if(pathname == "/"){
            mypath = "home"
        }else {
            const cleanedPathname = pathname.replace(/\//g, '');
            mypath = cleanedPathname
        }
        try {
            const res = await APICALL(`/get-data/meta-tags`);
            const metaTags = res?.data?.json_data;
            if (metaTags && Array.isArray(metaTags)) {
                const matchedMeta = metaTags.find((meta) => meta.page == mypath);
                if (matchedMeta) {
                    document.title = matchedMeta.meta_title || "Cue Solutions";
                    let metaDescription = document.querySelector('meta[name="description"]');
                    if (!metaDescription) {
                        metaDescription = document.createElement('meta');
                        metaDescription.name = "description";
                        document.head.appendChild(metaDescription);
                    }
                    metaDescription.content = matchedMeta.meta_description || "Cue Solutions";
                }
            }
    
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            <Header />
            <Component mainContent={mainContent} />
            <Footer footerContent={footerContent} />
        </>
    )
}

export default Main