import axios from 'axios';

export const apiBaseURL = "https://que-solutions.webupdatecenter.com/api";
export const imgBaseURL = "https://que-solutions.webupdatecenter.com/storage/";

const axiosInstance = axios.create({
    baseURL: 'https://que-solutions.webupdatecenter.com/api',
    headers: {
        'Content-Type': 'application/json',
    }
});

export const APICALL = async (endPoint = '', method = 'GET', value = null, headers = {}) => {
    try {
        const response = await axiosInstance({
            method: method,
            url: endPoint,
            data: value,
            headers: headers
        });
        return response.data;
    } catch (error) {
        console.error("API Call Error:", error);
        return error.response ? error.response.data : error.message;
    }
};
