import React, { useState } from 'react'
import { APICALL } from '../utils/api'

const Form = ({productDetails,quote}) => {
    const [value, setValue] = useState({
        "first_name" : '',
        "last_name" : '',
        "email" : '',
        "phone" : '',
        "message" : '',
    })
    const handleChange = (e) =>{
        setValue({
            ...value, [e.target.name] : e.target.value
        })
    }
    const [msg, setMsg] = useState('')
    const handleSubmit = async (e) => {
      e.preventDefault()
      try {
        const res = await APICALL('contact', 'post', {...value, product_id : productDetails?.id, quote: quote} )
        if (res?.status) {
            setValue({
                ...value, 'email' : '', 'first_name': '', 'last_name': "", "message": '', "phone": ''
            })
          setMsg(<span className="text-success">{res?.message}</span>)
        } else {
          setMsg(<span className="text-danger">{res?.message}</span>)
        }
      } catch (error) {
        setMsg(<span className="text-danger">{error?.response?.data?.message}</span>)
  
      }
    }
    return (
        <>
            <form class="needs-validation" novalidate onSubmit={handleSubmit}>
                <div class="row " novalidate>
                    <div class="col-md-6 mb-4 mt-0">
                        <input
                            type="text"
                            class="form-control"
                            name='first_name'
                            onChange={handleChange}
                            value={value.first_name}
                            id="your-name"
                            placeholder="First Name"
                            required
                        />
                    </div>
                    <div class="col-md-6 mb-4 mt-0">
                        <input
                            type="text"
                            class="form-control"
                            name='last_name'
                            onChange={handleChange}
                            value={value.last_name}
                            placeholder="Last Name"
                            required
                        />
                    </div>

                    <div class="col-md-6 mb-4 mt-0">
                        <input
                            type="tel"
                            class="form-control"
                            name='phone'
                            onChange={handleChange}
                            value={value.phone}
                            placeholder="Phone Number"
                            required
                        />
                    </div>

                    <div class="col-md-6 mb-4 mt-0">
                        <input
                            type="email"
                            class="form-control"
                            name='email'
                            onChange={handleChange}
                            value={value.email}
                            placeholder="Email Address"
                            required
                        />
                    </div>
                    <div class="col-12 mb-4 mt-0">
                        <textarea
                            class="form-control"
                            name='message'
                            onChange={handleChange}
                            value={value.message}
                            rows="4"
                            placeholder="Write Message..."
                            required
                        ></textarea>
                    </div>
                </div>
                <div className="d-md-flex align-item-center justify-content-between">
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="accept-terms"
                            required
                        />
                        <label class="form-check-label" for="accept-terms">
                            Accept terms and conditions from Graviton.
                        </label>
                        <div class="invalid-feedback">
                            You must agree before submitting.
                        </div>
                    </div>
                    <button class="btn" type="submit">
                        Submit <i class="fa fa-arrow-right"></i>
                    </button>
                </div>
                {msg}
            </form>
        </>
    )
}

export default Form