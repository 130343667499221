import React, { useState } from "react";

import Partners from "../Component/Partner";
import WhatWeOffer from "../Component/WhatWeOffer";
import Testimonials from "../Component/Testimonials";
import { imgBaseURL } from "../utils/api";
import { Link } from "react-router-dom";
import HTMLContent from "../Component/HTMLContent";
const About = ({ mainContent }) => {

  return (
    <>
      <div className="margindiv-header">
        <section className="breadrum-tittle" style={{backgroundImage: `url(${imgBaseURL + mainContent?.aboutUs?.banner})`,}}>
          <div className="container">
            <h1>About Us</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i className="fa fa-location-arrow"></i>
              </li>
              <li>
                <span>About Us</span>
              </li>
            </ul>
          </div>
        </section>

        <section className="about-innerpage">
          <div className="row mx-0">
            <div className="col-md-5 px-md-0">
              <div className="bg-about-inner h-100" style={{backgroundImage: `url(${imgBaseURL + mainContent?.aboutUs?.image})`,}}>
              </div>
            </div>
            <div className="col-md-7 px-md-0">
              <div className=" bg-about-content-outer">
                <div className="section-title bg-about-content">
                  <span className="main_header_tittle">About us</span>
                  <h2>
                  {mainContent?.aboutUs?.title}
                  </h2>
                  <p className="mb-xl-4">
                    <HTMLContent data={mainContent?.aboutUs?.text}/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <WhatWeOffer data={mainContent?.whatWeOffer} />

        {/* <section className="whatweoffer aboutus-whtaweoffer">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="section-title mb-md-5 mb-4">
                  <span>What We offer</span>
                  <h2>
                    Bespoke safety solutions, with a focus on end user
                    experience
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3  mb-md-0 mb-4 col-sm-6">
                <div className="whtwe-box">
                  <img src={safety} alt="" />
                  <h3>Health &amp; Safety</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
              <div className="col-md-3  mb-md-0 mb-4 col-sm-6">
                <div className="whtwe-box">
                  <img src={planning} alt="" />
                  <h3>Technology Solutions</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
              <div className="col-md-3  mb-md-0 mb-4 col-sm-6">
                <div className="whtwe-box">
                  <img src={management} alt="" />
                  <h3>Management</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
              <div className="col-md-3  mb-md-0 mb-4 col-sm-6">
                <div className="whtwe-box">
                  <img src={lightbulb} alt="" />
                  <h3>planning</h3>
                  <p>
                    Simple actions make a difference. It starts and ends with
                    each employee striving to work safer every single day so
                    they can return.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <Partners data={mainContent?.logos} />
        <Testimonials data={mainContent?.testimonials} />

      </div>
    </>
  );
};

export default About;
