import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { APICALL, imgBaseURL } from "../utils/api";

const Productlist = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 9;
  const navigate = useNavigate()

  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    geProductFun()
  }, [])
  const geProductFun = async () => {
    setLoading(true)
    try {
      const res = await APICALL(`/get-data/product`)
      if (res?.status) {
        setProducts(res?.data)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products?.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(products?.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  return (
    <>
      {
        loading ? <>Loading...</> :
          <>
            <div className="row">
              {currentProducts?.map((product) => (
                <div className="col-lg-4 col-md-4 col-sm-6 mb-4" key={product.id}>
                  <div className="product-box">
                    <Link to={`/product-details/${product?.id}`}>
                      <img variant="top" src={imgBaseURL + JSON.parse(product.image)[0]} alt={product.title} />
                      <div className="title-product">
                        <h2>{product.title}</h2>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <nav>
              <ul className="pagination justify-content-center mt-md-5 mt-4">
                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                  <button
                    className="page-link"
                    onClick={handlePreviousPage}
                    aria-label="Previous"
                  >
                    <i className="fa fa-chevron-left"></i>
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index + 1}
                    className={`page-item ${index + 1 === currentPage ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <button
                    className="page-link"
                    onClick={handleNextPage}
                    aria-label="Next"
                  >
                    <i className="fa fa-chevron-right"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </>
      }
    </>
  );
};

export default Productlist;
