import React from "react";

import Partners from "../Component/Partner";
import { Link } from "react-router-dom";
import { imgBaseURL } from "../utils/api";
import HTMLContent from "../Component/HTMLContent";
import Form from "../Component/Form";
const About = ({mainContent}) => {
  return (
    <>
      <div className="margindiv-header">
        <section className="breadrum-tittle" style={{backgroundImage: `url(${imgBaseURL + mainContent?.contactUs?.banner})`,}}>
          <div className="container">
            <h1>Contact Us</h1>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <i class="fa fa-location-arrow"></i>
              </li>
              <li>
                <span>Contact Us</span>
              </li>
            </ul>
          </div>
        </section>
        <section className="contact-quesolution">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 pe-md-4 mb-md-0 mb-4">
                <div className="section-title">
                  <span className="main_header_tittle">let's work together</span>
                  <p className="mb-xl-4">
                  <HTMLContent data={mainContent?.contactUs?.text} />
                  </p>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <div className="suppro-addres">
                        <p>Call Support</p>
                        <h3>
                          <a href={`tel:${mainContent?.contactUs?.contact}`}>{mainContent?.contactUs?.contact}</a>
                        </h3>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div class="conatctuseform" novalidate>
                <Form />

                </div>
              </div>
            </div>
          </div>
        </section>
        <Partners data={mainContent?.logos} />

      </div>
    </>
  );
};

export default About;
