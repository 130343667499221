import React from 'react'
import OwlCarousel from "react-owl-carousel";
import testmonialbg from "../../src/assets/img/h2-6.webp";
import quote from "../../src/assets/img/straight-quotes.png";
import { imgBaseURL } from '../utils/api';
import { Link } from 'react-router-dom';

const testimonial = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 5000,
    smartSpeed: 700,
    navText: [
        "<i class='fa fa-arrow-left'></i>",
        "<i class='fa fa-arrow-right'></i>",
    ],
};
const testmonialStyle = {
    backgroundImage: `url(${testmonialbg})`,
};

const Testimonials = ({ data }) => {
    const testimonials = data ? JSON.parse(data?.testimonials) : "";

    return (
        <>

            <section className="testimonial-section" style={testmonialStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="section-title">
                                <span className='main_header_tittle'>testimonials</span>
                                <img src={quote} alt="" />
                                <h2>What Your Client Say?</h2>
                            </div>
                            {
                                testimonials &&
                                <OwlCarousel
                                    className="owl-theme pxl-testimonial-carousel"
                                    {...testimonial}
                                >
                                    {
                                        testimonials?.map((item, i) => (
                                            <div className="item">
                                                <div className="pxl-item--inner">
                                                    <div className="pxl-item--desc"> {item?.comments} </div>
                                                    <div className="content-bottom">
                                                        <div className="pxl-item--holder pxl-flex-middle">
                                                            <div className="pxl-item--avatar">
                                                                <img src={imgBaseURL + item?.image} alt={item?.name} />
                                                            </div>
                                                            <div className="pxl-item--meta">
                                                                <h3>{item?.name}</h3>
                                                                <p>{item?.designation}</p>
                                                            </div>
                                                        </div>
                                                        <div className="btn-more">
                                                            <Link to={'#'}>
                                                                View more testimonials
                                                                <i className="fa fa-arrow-right"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </OwlCarousel>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonials