import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../src/assets/img/logo.png";
import footerbg from "../../src/assets/img/bg-ft-1.webp";
import HTMLContent from "../Component/HTMLContent";
import { APICALL } from "../utils/api";
import Form from "../Component/Form";

const footerStyle = {
  backgroundImage: `url(${footerbg})`,
};

function Footer({ footerContent }) {
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState('')
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await APICALL('newsletter', 'post', { email: email })
      console.log(res)
      if (res?.status) {
        setEmail("")
        setMsg(<span className="text-success">{res?.message}</span>)
      } else {
        setMsg(<span className="text-danger">{res?.message}</span>)
      }
    } catch (error) {
      setMsg(<span className="text-danger">{error?.response?.data?.message}</span>)

    }
  }
  const [show, setShow] = useState(false);

  return (
    <>
      <footer className="footer" style={footerStyle}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              {" "}
              <div className="ready-to-work">
                <HTMLContent data={footerContent?.description} />
                <button onClick={() => setShow(true)} className="btn pxl-icon-active">
                  <span className="pxl--btn-text"> Get A Quote </span>
                  <i aria-hidden="true" className="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="menu-footer">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              {/* <li>
              <Link to="/qtag">Qtag</Link>
            </li> */}
              <li>
                <Link to="/product">Products</Link>
              </li>
              <li>
                <Link to="/faq">Faq</Link>
              </li>
            </ul>
          </div>

          <div className="row my-md-5 my-4">
            <div className="col-md-4 mb-md-0 mb-4">
              <div className="logofooters">
                <a href="/">
                  <img src={logo} alt="Logo" />
                </a>
                <div className="social-media-links">
                  <Link to={footerContent?.facebook} target="_blank">
                    <i aria-hidden="true" className="fab fa-facebook-f"></i>
                  </Link>
                  <Link to={footerContent?.twitter} target="_blank">
                    <i aria-hidden="true" className="fab fa-twitter"></i>
                  </Link>
                  <Link to={footerContent?.skype} target="_blank">
                    <i aria-hidden="true" className="fab fa-skype"></i>
                  </Link>
                  <Link to={`mailto:${footerContent?.email}`}>
                    <i aria-hidden="true" className="fas fa-envelope"></i>
                  </Link>
                  <Link to={footerContent?.instagram} target="_blank">
                    <i aria-hidden="true" className="fab fa-instagram"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-md-0 mb-4">
              <div className="footer-widgets">
                <h3>Address Company</h3>
                <div className="pxl-icon-box">
                  <div className="pxl-item--meta">
                    <a href="https://www.google.com/maps" target="_blank">
                      <i aria-hidden="true" className="fas fa-map-marker-alt"></i>
                      <span> {footerContent?.address} </span>
                    </a>
                  </div>
                  <div className="pxl-item--meta">
                    <a href="tel:+18091206705">
                      <i aria-hidden="true" className="fa fa-phone"></i>
                      <span>Call Us: {footerContent?.phone}</span>
                    </a>
                  </div>
                  <div className="pxl-item--meta">
                    <a href="mailto:quesolution@gmail.com">
                      <i aria-hidden="true" className="fa fa-envelope"></i>
                      <span>Mail: {footerContent?.email}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-md-0 mb-4">
              <div className="footer-widgets">
                <h3>Subscribe Newsletter</h3>
                <p>
                  Subscribe to receive the latest news from us; we make sure not
                  to spam your inbox.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="input-filled">
                    <input
                      className="form-control"
                      placeholder="*Email address"
                      type="email"
                      name="your-email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <button type="submit" className="">
                      <i className="fa fa-arrow-right"></i>
                    </button>
                    <div className="mt-2">
                      {msg}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="pxl-el-divider"></div>
          <div className="copyright">
            <div className="row">
              <div className="col-md-6">
                <p>
                  Copyright © 2024 <Link to="/">Cue Solutions</Link>. All Rights
                  Reserved.
                </p>
              </div>
              <div className="col-md-6">
                <div className="privacy-links">
                  <a href="/privacy-policy">Privacy Policy</a> <a href="/terms-of-use">Terms of Use</a>
                  <a href="/sitemap">Site Map</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {show && (
        <div className="modal show" style={{ display: "block" }} tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content conatctuseform p-4 pt-3">
              <div className="modal-header p-0 border-0 mb-3">
                <h5 className="modal-title">GET A QUOTE</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={()=>setShow(false)}
                ></button>
              </div>
              <div className="modal-body px-0 pb-0">
                <Form quote="quote"/>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" onClick={()=>setShow(false)}></div>
        </div>
      )}
    </>
  );
}

export default Footer;
